import {createApp} from 'vue'
import App from './App.vue'
import './vendor.js';
import {Utm} from 'utm-parameters-toolset';
import UAParser from "ua-parser-js";

const getConfig = async (url) => {
    const response = await fetch(url);
    const json = await response.json();
    return json;
};

const main = async () => {

    const app = createApp(App);
    const domain = "prontovanlines.com";

    const utm = new Utm({
        domain: `.${domain}`,
        expires: 365,
    });
    utm.store();

    let visits = 1;
    if (localStorage) {
        const storageVisits = localStorage.getItem('VISITS');
        if (storageVisits) {
            visits = parseInt(storageVisits) + 1;
            localStorage.setItem('VISITS', visits);
        } else {
            localStorage.setItem('VISITS', visits);
        }
    }

    const baseUrl = `//${domain}/server/`;
    const api = {
        checkZip: baseUrl + 'CheckZip.php',
        sendForm: baseUrl + 'send_form.php',
        getConfig: baseUrl + 'config.client.json'
    };
    app.config.globalProperties.api = api;

    //const config = await getConfig(api.getConfig);
    const config = {
        "phoneFull": "18884062551",
        "phoneFormat": "(888) 406-2551",
        "phoneFormatSecond": "888.406.2551",
        "desktop": {
            "page_1": {
                "header_title": "The ProntoVanLines™ Moving Cost Calculator",
                "header_subtitle": "Trusted by over 1.2 million households!",
                "title": "Where are you",
                "subtitle": "Moving From?"
            }
        },
        "mobile": {
            "page_1": {
                "header_title": "Moving Cost Calculator",
                "header_subtitle": "Get Free Long Distance & Local Moving Quote",
                "title": "Where are you moving from?"
            }
        }
    };

    const getDevice = () => {
        return ((window.innerWidth > 0) ? window.innerWidth : screen.width) < 760 ? 'mobile' : 'desktop';
    };
    const parseZip = async (zip) => {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=AIzaSyBjlDFLI9tDjvK-sDqqFeRrsMj6NuMDNs4&language=en`);
        const json = await response.json();
        if (String(json.status) === 'ZERO_RESULTS') {
            return null;
        }
        const data = {
            city: "",
            countryCode: "",
            stateCode: "",
            zipCode: ""
        };
        const results = json.results[0];
        const addressComponents = results.address_components;
        for (const component of addressComponents) {
            if (component.types.includes('postal_code')) {
                data.zipCode = component.long_name;
                continue;
            }
            if (component.types.includes('locality')) {
                data.city = component.long_name;
                continue;
            }
            if (component.types.includes('administrative_area_level_1')) {
                data.stateCode = component.short_name;
                continue;
            }
            if (component.types.includes('country')) {
                data.countryCode = component.short_name;
                continue;
            }
        }
        return data;
    };
    const getDeviceData = () => {
        const parser = new UAParser();
        const browser = parser.getBrowser();
        const device = parser.getDevice();
        const os = parser.getOS();
        const formDeviceData = [];
        if (browser.name || browser.version) {
            formDeviceData.push(`${browser.name || ''} ${browser.version || ''}`);
        }
        if (device.type || device.model || device.vendor) {
            formDeviceData.push(`${device.type || ''} ${device.model || ''} ${device.vendor || ''}`);
        }
        if (os.name && os.version) {
            formDeviceData.push(`${os.name || ''} ${os.version}`);
        }
        return formDeviceData.join(' | ');
    };

    app.config.globalProperties.config = config;
    app.config.globalProperties.device = getDevice();
    app.config.globalProperties.deviceData = getDeviceData();
    app.config.globalProperties.$parseZip = parseZip;
    app.config.globalProperties.$utm = utm;
    app.config.globalProperties.ILANDPAGE = domain;
    app.config.globalProperties.VISITS = visits;

    app.mount('#app');
};

main();
