<template>
  <div v-if="device === ''">
    Loading...
  </div>
  <div class="flex flex-auto" v-if="device === 'mobile'">
  <AsyncMobile/>
  </div>
  <div class="flex flex-auto" v-if="device === 'desktop'">
    <AsyncDesktop/>
  </div>
</template>

<script>
import {defineAsyncComponent} from 'vue';

export default {
  name: 'App',
  components: {
    AsyncMobile: defineAsyncComponent(() => import('./components/MobileApp.vue')),
    AsyncDesktop: defineAsyncComponent(() => import('./components/DesktopApp.vue')),
  },
  data() {
    return {
      device: this.device
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}
</style>
